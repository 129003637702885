<script>
import { defineComponent, computed, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    totalData: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const active = ref('contractChannel')
    const formatterNumber = value => (value && Number(value)?.toLocaleString()) || 0

    const list = computed(() => [
      {
        name: '签约频道总数 (个)',
        icon: 'icon-a-bofangcishu2x',
        count: formatterNumber(props.totalData.signChannelCount),
        tips: '当前合作中的国内频道总数',
        key: 'contractChannel',
      },
      {
        name: '国外频道总数(个)',
        icon: 'icon-a-guowaipindaozongshu2x',
        count: formatterNumber(props.totalData.overseaChannelCount),
        tips: '当前正在运营的国外频道数（不包含终止合作客户的频道）',
        key: 'overseaChannel',
      },
      {
        name: '签约视频总数 (个)',
        icon: 'icon-a-qianyueshipinzongshu2x',
        count: formatterNumber(props.totalData.signVideoCount),
        tips: '从2010年至今发往正在合作的国内频道的视频总数',
        key: 'contractVideo',
      },
      {
        name: '发布视频总数 (个)',
        icon: 'icon-a-fabushipinzongshu2x',
        count: formatterNumber(props.totalData.overseaVideoCount),
        tips: '从2016年至今发往海外社媒平台的视频总数',
        key: 'publishVideo',
      },
      {
        name: '签约视频总时长 (h)',
        icon: 'icon-a-qianyueshipinzongshichang2x',
        count: formatterNumber(props.totalData.signVideoDurationCount),
        tips: '从2010年至今发往正在合作的国内频道的视频总时长，单位小时',
        key: 'contractVideoDuration',
      },
      {
        name: '发布视频总时长 (h)',
        icon: 'icon-a-fabushipinzongshichang2x',
        count: formatterNumber(props.totalData.overseaVideoDurationCount),
        tips: '从2016年至今发往海外社媒平台的视频总时长，单位小时',
        key: 'publishVideoDuration',
      },
      {
        name: '异常报错数 (次)',
        icon: 'icon-a-yichangbaocuoshu2x',
        count: formatterNumber(props.totalData.errorCount),
        tips: '平台发出版权警示和社区警告的总视频数',
        key: 'errorCount',
      },
    ])

    const handleClick = item => {
      active.value = item.key
      emit('change', item)
    }

    return {
      list,
      active,
      handleClick,
    }
  },
})
</script>

<template>
  <div class="total-box">
    <div
      v-for="item, index in list"
      :key="index"
      class="total-item"
      :class="active === item.key ? 'active' : ''"
      :style="isLoading ? 'opacity: 0.5': ''"
      @click="!isLoading && handleClick(item)"
    >
      <div
        class="iconfont total-icon"
        :class="item.icon"
      ></div>
      <div class="total-count">
        {{ item.count }}
      </div>
      <div class="total-name">
        {{ item.name }}
        <v-tooltip bottom>
          <template
            #activator="{ on, attrs }"
          >
            <span
              v-bind="attrs"
              class="ml-1 iconfont icon-a-tishishuoming2x2"
              style="font-size: 14px;"
              v-on="on"
            ></span>
          </template>
          <div>{{ item.tips }}</div>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.total {
  &-box {
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: row;
  }
  &-item{
    flex-grow: 1;
    height: 144px;
    padding: 20px;
    width: 224px;
    background-color: #fff;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid #fff;
    box-sizing: border-box;
  }
  &-item:last-child{
    margin-right: 0;
  }
  &-icon{
    color: rgba(212, 212, 212, 1);
    font-size:25px;
    line-height: 25px;
  }
  &-count{
    font-size: 30px;
    color: #000;
    font-weight: bold;
    margin-top: 8px;
    line-height: 42px;
  }
  &-name{
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    color: rgba(0, 0, 0, 0.50);
  }
  &-item.active, &-item:hover{
    border: 1px solid var(--v-primary-base);
    background:  rgba(145,85,253,0.04);
  }
  &-item.active &-name, &-item.active &-icon, &-item:hover &-icon, &-item:hover &-name{
    color: var(--v-primary-base);
  }

}
</style>
