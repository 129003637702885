<script>
import {
  defineComponent, ref, nextTick, onUnmounted, onMounted,
} from '@vue/composition-api'
import * as echarts from 'echarts'

export default defineComponent({
  props: {
    xAxis: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '各周期签约数量',
    },
    name: {
      type: String,
      default: '签约数量',
    },
    unit: {
      type: String,
      default: '个',
    },
  },
  setup(props) {
    const chartRef = ref()
    const chartInstance = ref(null)
    const chartOptions = ref({
      title: {
        text: props.title,
        left: 20,
        top: 0,
        show: true,
        textStyle: {
          color: '#000',
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(78, 89, 105, 0.90)',
        textStyle: {
          color: '#fff',
        },
        borderWidth: 0,
      },
      grid: {
        left: 100,
        right: 100,
      },
      xAxis: {
        data: props.xAxis,
        name: '时间',
      },
      yAxis: {
        name: props.unit === 'h' ? '时长（小时）' : `数量（${props.unit}）`,
      },
      series: [
        {
          name: props.name,
          type: 'line',
          data: props.data,
          lineStyle: {
            color: 'rgba(173, 146, 255, 1)',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'rgba(173, 146, 255, 0.40)', // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(173, 146, 255, 0)', // 100% 处的颜色
              }],
              global: false, // 缺省为 false
            },
          },
        },
      ],
    })

    nextTick(() => {
      chartInstance.value = echarts.init(chartRef.value)
      chartInstance.value.setOption(chartOptions.value, true)
      chartInstance.value.resize()
    })

    onMounted(() => {
      window.addEventListener('resize', () => {
        chartInstance.value.resize()
      })
    })

    onUnmounted(() => {
      if (chartInstance.value) {
        chartInstance.value.dispose()
      }
    })

    return {
      chartRef,
    }
  },
})
</script>

<template>
  <div
    ref="chartRef"
    class="chart"
  ></div>
</template>
<style lang="scss" scoped>
.chart{
  width: 100%;
  height: 100%;
  padding-top: 16px;
}
</style>
