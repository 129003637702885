<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import PieChart from '@/views/home/components/PieChart.vue'
import LineChart from '@/views/home/components/LineChart.vue'
import BarChart from '@/views/home/components/BarChart.vue'
import YBarChart from '@/views/home/components/YBarChart.vue'
import TotalBox from '@/views/home/components/TotalBox.vue'
import Conditions from '@/views/home/components/Conditions.vue'
import CategoryBox from '@/views/home/components/CategoryBox.vue'
import { getPage, getTotal } from '@/views/home/api'

export default defineComponent({
  components: {
    PieChart,
    LineChart,
    BarChart,
    YBarChart,
    TotalBox,
    Conditions,
    CategoryBox,
  },
  setup() {
    const active = ref('contractChannel')
    const platform = ref('')
    const searchParams = ref({})
    const isLoading = ref(false)
    const categoryActive = ref({})
    const totalData = ref({})
    const conditionsRef = ref(null)
    const platformOptions = ref([
      {
        label: '全部',
        value: '',
      },
      {
        label: 'YT',
        value: 'YT',
      },
      {
        label: 'FB',
        value: 'FB',
      },
    ])
    const pageData = ref({})
    const { message } = useMessage()

    const fetchList = async () => {
      // 获取数据
      isLoading.value = true
      const otherParams = {}
      if (active.value === 'publishVideo') {
        otherParams.regionType = platform.value
      }
      const { data: { data, status, message: messageText } } = await getPage({
        startTime: null,
        endTime: null,
        active: active.value,
        ...searchParams.value,
        type: searchParams.value.type === 'date' ? 'day' : searchParams.value.type || 'year',
        ...otherParams,
      })
      if (status === 200) {
        pageData.value = data
        if (data.tree) {
          const {
            tree: [{
              name, count, children = [], id,
            }],
          } = data
          categoryActive.value = {
            name,
            count,
            children,
            key: id,
          }
        }
      } else {
        message.error(messageText)
      }
      isLoading.value = false
    }

    const fetchTotal = async () => {
      const { data: { data, status, message: messageText } } = await getTotal()
      if (status === 200) {
        totalData.value = data
      } else {
        message.error(messageText)
      }
    }

    const handleChangeTab = ({ key }) => {
      // 显示tab
      active.value = key
      searchParams.value = {}
      conditionsRef.value.reset()
      fetchList()
    }

    const handleSeach = params => {
      // 搜索
      searchParams.value = params
      fetchList()
    }

    const handlePlatformChange = () => {
      fetchList()
    }

    const changeCategory = item => {
      // 切换分类
      categoryActive.value = item
      console.log(categoryActive.value)
    }

    const getSeriesData = (data = []) => {
      const result = []
      data.forEach(item => {
        if (item.children) {
          result.push(...item.children)
        }
      })
      const resultKeys = [...new Set(result.map(item => item.type))]

      return resultKeys.map(key => ({
        name: result.find(item => item.type === key)?.name || result.find(item => item.type === key).type,
        type: key,
        data: result.filter(item => item.type === key).map(item => item.count),
      }))
    }

    onMounted(() => {
      fetchList()
      fetchTotal()
    })

    return {
      isLoading,
      active,
      platform,
      totalData,
      platformOptions,
      pageData,
      categoryActive,
      conditionsRef,
      handleChangeTab,
      handleSeach,
      changeCategory,
      getSeriesData,
      handlePlatformChange,
    }
  },
})
</script>

<template>
  <div class="wrapper">
    <!--tab区域-->
    <TotalBox
      :total-data="totalData"
      :is-loading="isLoading"
      @change="handleChangeTab"
    />

    <!--筛选条件-->
    <Conditions
      ref="conditionsRef"
      @change="handleSeach"
    />

    <v-progress-linear
      v-if="isLoading"
      indeterminate
    />

    <div v-if="!isLoading">
      <!--图表区域-签约频道总数-->
      <div
        v-if="active === 'contractChannel'"
        key="contractChannel"
        class="chart-box"
      >
        <div class="chart-item">
          <!--签约平台分布-->
          <PieChart
            :data="(pageData.platform|| []).map(item => ({name: item.platform, value: item.platformCount}))"
            class="lt"
            title="签约平台分布图"
            name="签约频道数"
          />
          <!--签约时间分布-->
          <LineChart
            class="rt"
            title="签约量时间趋势图"
            name="签约频道数"
            :data="(pageData.time|| []).map(item => item.timeCount)"
            :x-axis="(pageData.time|| []).map(item => item.time)"
          />
        </div>
        <div class="chart-item">
          <!--签约类型分布-->
          <!-- <PieChart
            class="lt"
            title="签约类型分布"
            name="签约频道数"
          /> -->
          <!--授权平台发布-->
          <BarChart
            class="rt"
            title="授权平台签约量对比图"
            name="新增签约"
            unitx="平台"
            :data="(pageData.authorizePlatform|| []).map(item => item.authorizePlatformCount)"
            :x-axis="(pageData.authorizePlatform|| []).map(item => item.authorizePlatform)"
          />
        </div>
        <!--签约垂类分布-->
        <div
          v-if="false"
          class="chart-max"
        >
          <div class="chart-title">
            签约垂类分布
          </div>
          <!--各个垂类筛选-->
          <CategoryBox
            :list="pageData.tree"
            @change="changeCategory"
          />
          <!--签约垂类分布-->
          <YBarChart
            v-if="categoryActive.children"
            :key="categoryActive.key"
            title="签约垂类分布"
            name="频道个数"
            :x-axis="(categoryActive.children|| []).map(item => item.name)"
            :data="(categoryActive.children|| []).map(item => item.count)"
          />
        </div>
      </div>

      <!--图表区域-国外频道总数-->
      <div
        v-if="active === 'overseaChannel'"
        key="overseaChannel"
        class="chart-box"
      >
        <div
          class="chart-item"
          style="height: 600px"
        >
          <!--创建平台分布-->
          <PieChart
            class="lt"
            title="创建平台分布图"
            name="国外频道创建数"
            :data="(pageData.platform|| []).map(item => ({name: item.platform, value: item.platformCount}))"
          />
          <!--创建时间分布-->
          <LineChart
            class="rt"
            title="创建量时间趋势图"
            name="频道创建数"
            :data="(pageData.time|| []).map(item => item.timeCount)"
            :x-axis="(pageData.time|| []).map(item => item.time)"
          />
        </div>
        <!--创建垂类分布-->
        <div
          v-if="false"
          class="chart-max"
        >
          <div class="chart-title">
            创建垂类分布
          </div>
          <!--各个垂类筛选-->
          <CategoryBox
            :list="pageData.tree"
            @change="changeCategory"
          />
          <!--创建垂类分布-->
          <YBarChart
            v-if="categoryActive.children"
            :key="categoryActive.key"
            title="创建垂类分布"
            name="频道创建数"
            :x-axis="(categoryActive.children|| []).map(item => item.name)"
            :data="(categoryActive.children|| []).map(item => item.count)"
          />
        </div>
      </div>

      <!--图表区域-签约视频总数-->
      <div
        v-if="active === 'contractVideo'"
        key="contractVideo"
        class="chart-box"
      >
        <div
          class="chart-item"
          style="height: 600px"
        >
          <!--签约平台分布-->
          <PieChart
            class="lt"
            title="签约平台分布图"
            name="签约视频数"
            :data="(pageData.platform|| []).map(item => ({name: item.platform, value: item.platformCount}))"
          />
          <!--签约时间分布-->
          <LineChart
            class="rt"
            title="视频数时间趋势图"
            name="签约视频数"
            :data="(pageData.time|| []).map(item => item.timeCount)"
            :x-axis="(pageData.time|| []).map(item => item.time)"
          />
        </div>
      </div>

      <!--图表区域-发布视频总数-->
      <div
        v-if="active === 'publishVideo'"
        key="publishVideo"
        class="chart-box"
      >
        <div class="chart-item">
          <!--发布平台分布-->
          <PieChart
            class="lt"
            title="发布平台分布图"
            name="发布视频数"
            :data="(pageData.platform|| []).map(item => ({name: item.platform, value: item.platformCount}))"
          />
          <!--发布时间分布-->
          <LineChart
            class="rt"
            title="发布量时间趋势图"
            name="发布视频数"
            :data="(pageData.time|| []).map(item => item.timeCount)"
            :x-axis="(pageData.time|| []).map(item => item.time)"
          />
        </div>
        <!--发布方式分布-->
        <div class="chart-filter">
          <div class="select-box">
            <label>发布平台</label>
            <v-autocomplete
              v-model.trim="platform"
              :items="platformOptions"
              item-text="label"
              item-value="value"
              no-data-text="暂无数据"
              hide-details="auto"
              placeholder="发布平台"
              dense
              outlined
              clearable
              style="width: 250px"
              @change="handlePlatformChange"
            ></v-autocomplete>
          </div>
          <!--发布方式分布-->
          <PieChart
            class="lt"
            title="发布方式分布图"
            name="发布视频数"
            :data="(pageData.type|| []).map(item => ({name: item.type, value: item.count}))"
          />
          <!--各方式发布视频数-->
          <BarChart
            class="rt"
            title="发布方式分布图"
            name="发布视频数"
            unitx="日期"
            :x-axis="(pageData.typeTime|| []).map(item => item.time)"
            :series="getSeriesData(pageData.typeTime|| [])"
          />
        </div>
      </div>

      <!--图表区域-签约视频总时长-->
      <div
        v-if="active === 'contractVideoDuration'"
        key="contractVideoDuration"
        class="chart-box"
      >
        <div
          class="chart-item"
          style="height: 600px"
        >
          <!--签约平台分布-->
          <PieChart
            class="lt"
            title="签约平台分布图"
            name="签约视频时长"
            unit="h"
            :is-video-duration="true"
            :data="(pageData.platform|| []).map(item => ({name: item.platform, value: item.platformCount}))"
          />
          <!--签约时间分布-->
          <LineChart
            class="rt"
            title="视频时长时间趋势图"
            name="签约视频时长"
            unit="h"
            :is-video-duration="true"
            :data="(pageData.time|| []).map(item => item.timeCount)"
            :x-axis="(pageData.time|| []).map(item => item.time)"
          />
        </div>
      </div>

      <!--图表区域-发布视频总时长-->
      <div
        v-if="active === 'publishVideoDuration'"
        key="publishVideoDuration"
        class="chart-box"
      >
        <div
          class="chart-item"
          style="height: 600px"
        >
          <!--发布平台分布-->
          <PieChart
            class="lt"
            title="发布平台分布图"
            name="发布视频时长"
            unit="h"
            :is-video-duration="true"
            :data="(pageData.platform|| []).map(item => ({name: item.platform, value: item.platformCount}))"
          />
          <!--发布时间分布-->
          <LineChart
            class="rt"
            title="视频时长时间趋势图"
            name="发布视频时长"
            unit="h"
            :is-video-duration="true"
            :data="(pageData.time|| []).map(item => item.timeCount)"
            :x-axis="(pageData.time|| []).map(item => item.time)"
          />
        </div>
      </div>

      <!--图表区域-异常报错数-->
      <div
        v-if="active === 'errorCount'"
        Key="errorCount"
        class="chart-box"
      >
        <div
          class="chart-item"
          style="height: 600px"
        >
          <!--异常报错类型分布-->
          <PieChart
            class="lt"
            title="异常类型分布图"
            name="异常报错数"
            unit="次"
            :data="(pageData.errorType|| []).map(item => ({name: item.errorType, value: item.errorCount}))"
          />
          <!--异常报错时间分布-->
          <BarChart
            class="rt"
            title="异常报错时间分布图"
            name="异常报错数"
            unit="次"
            :x-axis="(pageData.subTime|| []).map(item => item.time)"
            :series="getSeriesData(pageData.subTime|| [])"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.wrapper{
  width: 100%;
  height: 100%;
  border-radius: 6px;
  .chart-box{
    width: 100%;
    .lt{
      width: 460px;
      min-height: 400px;
      background-color: #fff;
      border-radius: 6px;
      margin-right: 12px;
    }
    .rt{
      flex: 1;
      background-color: #fff;
      border-radius: 6px;
      min-height: 400px;
    }
  }
  .chart-item{
    width: 100%;
    display: flex;
    padding-bottom: 12px;
  }
  .chart-max {
    padding: 16px 20px;
    background-color: #fff;
    width: 100%;
    display: block;
    box-sizing: border-box;
    .chart-title{
      font-size: 16px;
      color: #000;
      font-weight: bold;
    }
  }
  .chart-filter{
    padding: 66px 20px 16px 20px;
    background-color: #fff;
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    .select-box{
      position: absolute;
      right: 20px;
      top: 16px;
      display: flex;
      align-items: center;
      label{
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
}
</style>
