<script>
import {
  defineComponent, ref, nextTick, onUnmounted, onMounted,
} from '@vue/composition-api'
import * as echarts from 'echarts'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '各平台签约数量',
    },
    name: {
      type: String,
      default: '签约频道数',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const chartRef = ref()
    const chartInstance = ref(null)
    const chartOptions = ref({
      title: {
        text: props.title,
        left: 20,
        top: 16,
        show: false,
        textStyle: {
          color: '#000',
          fontSize: 16,
        },
      },
      color: ['#AD92FF', '#89DC31', '#F7C038', '#63A4FF', '#FF9977', '#63C0FF', '#BFBFBF'],
      legend: {
        left: 280,
        align: 'left',
        top: 'center',
        orient: 'vertical',
        icon: 'circle',
        itemGap: 10,
        itemWidth: 14,
        itemHeight: 6, // 修改icon图形大小
        selectedMode: false,
        textStyle: {
          fontSize: 12,
          fontWeight: 400,
        },
        formatter(name) {
          const value = (props.data || []).find(item => item.name === name)?.value

          return `${name}:  ${value}`
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {d}%',
        backgroundColor: 'rgba(78,89,105,0.9)',
        textStyle: {
          color: '#fff',
          fontWeight: 400,
        },
        borderWidth: 0,
      },
      graphic: {
        type: 'group',
        top: 'center',
        width: 300,
        height: 300,
        originX: 'center',
        children: [
          {
            type: 'text',
            top: 210,
            width: 300,
            height: 300,
            left: 'center', // 水平定位到中间
            style: {
              text: props.name,
              align: 'center',
              fontSize: 12,
              fontWeight: 200,
            },
          },
          {
            type: 'text',
            left: 'center', // 水平定位到中间
            top: 180,
            width: 300,
            height: 300,
            style: {
              text: (props.data || []).reduce((a, b) => Number(a) + Number(b.value), 0)?.toLocaleString(),
              fontSize: 20,
              align: 'center',
              fontWeight: 600,
            },
          },
        ],

      },
      series: [
        {
          type: 'pie',
          left: 0,
          top: 44,
          data: props.data,
          width: 300,
          height: 300,
          label: {
            show: true,
            position: 'inner',
            formatter: '{d}%',
            color: '#fff',
            fontSize: 12,
            fontWeight: 200,
          },
          radius: ['40%', '70%'],
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
            fontWeight: 400,
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              position: 'center',
              fontWeight: 'bold',
              formatter: '{c}',
            },
          },
        },
      ],
    })

    nextTick(() => {
      chartInstance.value = echarts.init(chartRef.value)
      chartInstance.value.setOption(chartOptions.value, true)
      chartInstance.value.resize()
    })

    onMounted(() => {
      window.addEventListener('resize', () => {
        chartInstance.value.resize()
      })
    })

    onUnmounted(() => {
      if (chartInstance.value) {
        chartInstance.value.dispose()
      }
    })

    return {
      chartRef,
    }
  },
})
</script>

<template>
  <div class="chart-box">
    <div class="title">
      {{ title }}
    </div>
    <div
      ref="chartRef"
      class="chart"
    ></div>
  </div>
</template>
<style lang="scss" scoped>
.chart-box{
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  .title{
    position: absolute;
    left: 24px;
    top: 11px;
    font-size: 16px!important;
    color: #000;
    font-weight: bold;
  }
}
.chart{
  width: 460px;
  height: 400px;
}
</style>
