<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import {
  oneWeek, oneMonth, halfYear, oneYear, dateGen, oneDay,
} from '@core/utils/dataProcess'
import DateRangePickers from '@core/components/search-form/DateRangePickers.vue'
import dayjs from 'dayjs'

export default defineComponent({
  components: {
    DateRangePickers,
  },
  setup(props, { emit }) {
    const date = ref([null, null])
    const dateType = ref('date')
    const active = ref('year')
    const currItem = ref({})
    const datePickerKey = ref(1)

    const pickerConfig = ref([
      {
        name: '全部',
        type: 'year',
        key: 'year',
        date: [null, null],
      },
      {
        name: '近7天',
        type: 'date',
        key: 'week',
        date: [dateGen(new Date(new Date() - oneWeek + oneDay)), dateGen(new Date())],
      },
      {
        name: '近30天',
        type: 'date',
        key: 'oneMonth',
        date: [dateGen(new Date(new Date() - oneMonth + oneDay)), dateGen(new Date())],
      },
      {
        name: '近半年',
        type: 'month',
        key: 'halfYear',
        date: [dayjs(dateGen(new Date(new Date() - halfYear + oneMonth))).format('YYYY-MM'), dayjs(dateGen(new Date())).format('YYYY-MM')],
      },
      {
        name: '近一年',
        type: 'month',
        key: 'oneYear',
        date: [dayjs(dateGen(new Date(new Date() - oneYear + oneMonth))).format('YYYY-MM'), dayjs(dateGen(new Date())).format('YYYY-MM')],
      },
    ])

    const reset = () => {
      date.value = [null, null]
      dateType.value = 'date'
      active.value = 'year'
      datePickerKey.value += 1
    }

    const handleClick = item => {
      date.value = item.date
      dateType.value = item.type
      active.value = item.key
      currItem.value = item

      emit('change', {
        type: dateType.value,
        startTime: date.value[0],
        endTime: date.value[1],
      })
    }

    watch(date, val => {
      if (!val.length) {
        reset()
        emit('change', {
          type: dateType.value,
          startTime: date.value[0],
          endTime: date.value[1],
        })
      }
    })

    const handleDateChange = () => {
      active.value = null
      emit('change', {
        type: dateType.value,
        startTime: date.value[0],
        endTime: date.value[1],
      })
    }

    return {
      active,
      date,
      dateType,
      pickerConfig,
      currItem,
      datePickerKey,
      reset,
      handleClick,
      handleDateChange,
    }
  },
})
</script>

<template>
  <div class="conditions">
    <div class="custom-picker">
      <div
        v-for="item in pickerConfig"
        :key="item.key"
        class="picker-item"
        :class="active === item.key ? 'active' : ''"
        @click="handleClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div

      v-show="dateType === 'month'"
      class="date-picker"
    >
      <DateRangePickers
        :key="`month${datePickerKey}`"
        v-model="date"
        type="month"
        :range="true"
        no-title
        width="250"
        clearable
        placeholder="全部"
        @change="handleDateChange"
      />
    </div>
    <div

      v-show="dateType !== 'month'"
      class="date-picker"
    >
      <DateRangePickers
        :key="`date${datePickerKey}`"
        v-model="date"
        :range="true"
        no-title
        width="250"
        type="date"
        clearable
        placeholder="全部"
        @change="handleDateChange"
      />
      <span class="iconfont icon-a-riqi2x"></span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.conditions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
  .custom-picker{
    display: flex;
    margin-right: 16px;
    .picker-item{
      width: 68px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-right: 1px solid #fff;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.50);
      cursor: pointer;
    }
    .picker-item.active, .picker-item:hover{
      color: var(--v-primary-base);
      border: 1px solid var(--v-primary-base);
    }
    .picker-item:first-child{
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .picker-item:last-child{
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    .picker-item.active:last-child, .picker-item:hover:last-child{
      border-right: 1px solid var(--v-primary-base);
    }
  }
  .date-picker{
    background-color: #fff;
    position: relative;
    ::v-deep .v-input__slot{
      min-height: 32px!important;
    }
    .iconfont{
      position: absolute;
      right: 10px;
      top: 4px;
    }
  }
  ::v-deep .v-input__append-inner{
    margin-top: 4px!important;
    margin-right: 2px;
  }
}
</style>
