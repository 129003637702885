<script>
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const categoryList = computed(() => (props.list || []).map(item => ({
      name: item.name,
      count: item.count,
      key: item.id,
      children: item.children,
    })))

    const active = ref(categoryList.value[0]?.key)

    const handleClick = item => {
      active.value = item.key
      emit('change', item)
    }

    return {
      active,
      categoryList,
      handleClick,
    }
  },
})
</script>

<template>
  <div class="category-box">
    <div
      v-for="(item) in categoryList"
      :key="item.key"
      class="category-item"
      :class="{ active: active === item.key }"
      @click="handleClick(item)"
    >
      <div class="category-name">
        {{ item.name }}
      </div>
      <div class="category-count">
        {{ item.count && Number(item.count).toLocaleString() }}
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.category{
  &-box{
    display: flex;
    align-items: center;
    padding: 40px 100px 0 100px;
    justify-content: center;
  }
  &-item{
    width: 150px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F6F7F8;
    border-radius: 6px;
    border: 1px solid #F6F7F8;
    margin-right: 12px;
    cursor: pointer;
  }
  &-name{
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: rgba(0,0,0,0.85);
  }
  &-count{
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin-top: 4px;
    color: rgba(0,0,0,0.6);
  }
  &-item.active,&-item:hover{
    background: rgba(145,85,253,0.04);
    border: 1px solid #9155FD;
  }
  &-item.active &-name,&-item:hover &-name{
    color: var(--v-primary-base);
  }
  &-item.active &-count,&-item:hover &-count{
    color: rgba(0,0,0,0.8);
  }
}
</style>
