<script>
import {
  defineComponent, ref, nextTick, onUnmounted, computed, onMounted,
} from '@vue/composition-api'
import * as echarts from 'echarts'

export default defineComponent({
  props: {
    xAxis: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '各授权平台签约数量',
    },
    name: {
      type: String,
      default: '新增签约',
    },
    series: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: '个',
    },
    unitx: {
      type: String,
      default: '时间',
    },
  },
  setup(props) {
    const chartRef = ref()
    const chartInstance = ref(null)
    const series = computed(() => {
      if (props.series && props.series.length) {
        return props.series.map(item => ({
          type: 'bar',
          barMaxWidth: 30,
          name: item.name,
          data: item.data,
          itemStyle: {
            borderRadius: [4, 4, 0, 0],
          },
          label: {
            show: true,
            position: 'top',
          },
        }))
      }

      return [
        {
          type: 'bar',
          barWidth: 30,
          name: props.name,
          data: props.data,
          itemStyle: {
            borderRadius: [4, 4, 0, 0],
          },
          label: {
            show: true,
            position: 'top',
          },
        },
      ]
    })
    const chartOptions = ref({
      title: {
        text: props.title,
        left: 20,
        top: 0,
        textStyle: {
          color: '#000',
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(78, 89, 105, 0.90)',
        borderWidth: 0,
        textStyle: {
          color: '#fff',
        },
      },
      grid: {
        left: 100,
        right: 100,
      },
      xAxis: {
        data: props.xAxis,
        name: props.unitx,
        offset: 10,
      },
      yAxis: {
        name: props.unit === 'h' ? '时长（小时）' : `数量（${props.unit}）`,
        offset: 10,
      },
      color: ['#AD92FF', '#89DC31', '#F7C038', '#63A4FF', '#FF9977', '#63C0FF', '#BFBFBF'],
      series: [...series.value],
    })

    nextTick(() => {
      chartInstance.value = echarts.init(chartRef.value)
      chartInstance.value.setOption(chartOptions.value, true)
      chartInstance.value.resize()
    })

    onMounted(() => {
      window.addEventListener('resize', () => {
        chartInstance.value.resize()
      })
    })

    onUnmounted(() => {
      if (chartInstance.value) {
        chartInstance.value.dispose()
      }
    })

    return {
      chartRef,
    }
  },
})
</script>

<template>
  <div
    ref="chartRef"
    class="chart"
  ></div>
</template>
<style lang="scss" scoped>
.chart{
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 16px;
}
</style>
