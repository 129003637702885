import axios from '@axios'
import url from './url'
import config from '../../../config'

const prefix = config.serviceUrl

/**
 * 获取线索审核列表
 * @param {type} 类型day、month、year
 * @param {startTime} 开始时间
 * @param {endTime} 结束时间
 */

export const getPage = params => {
  const { active, ...others } = params
  const URL = {
    contractChannel: url.CONTRACT,
    overseaChannel: url.OVERSEAS,
    publishVideo: url.VIDEO_OVERSEA,
    publishVideoDuration: url.VIDEO_DURATION_OVERSEA,
    contractVideo: url.VIDEO_SIGN,
    contractVideoDuration: url.VIDEO_DURATION_SIGN,
    errorCount: url.ERROR,
  }

  return axios.get(prefix + URL[active], { params: { ...others } })
}

/**
 * 获取总计
 */
export const getTotal = () => axios.get(prefix + url.HEAD)
