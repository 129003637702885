<script>
import {
  defineComponent, ref, nextTick, onUnmounted, onMounted,
} from '@vue/composition-api'
import * as echarts from 'echarts'

export default defineComponent({
  props: {
    xAxis: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '各授权平台签约数量',
    },
    name: {
      type: String,
      default: '新增签约',
    },
  },
  setup(props) {
    const chartRef = ref()
    const chartInstance = ref(null)
    const chartOptions = ref({
      title: {
        text: props.title,
        left: 20,
        top: 0,
        show: false,
        textStyle: {
          color: '#000',
          fontSize: 16,
        },
      },
      xAxis: {
      },
      yAxis: {
        type: 'category',
        data: props.xAxis,
      },
      color: [
        {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [{
            offset: 0.6, color: '#D6C4FF', // 0% 处的颜色
          }, {
            offset: 1, color: '#AD92FF', // 100% 处的颜色
          }],
          global: false, // 缺省为 false
        }, {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [{
            offset: 0.6, color: '#89DC31', // 0% 处的颜色
          }, {
            offset: 1, color: '#89DC31', // 100% 处的颜色
          }],
          global: false, // 缺省为 false
        }],
      series: [
        {
          type: 'bar',
          data: (props.data || []).sort((a, b) => a - b),
          colorBy: 'data',
          itemStyle: {
            borderRadius: [0, 20, 20, 0],
          },
          barWidth: 30,
          label: {
            show: true,
            position: 'right',
            formatter(data) {
              return data.value.toLocaleString()
            },
          },
        },
      ],
    })

    nextTick(() => {
      chartInstance.value = echarts.init(chartRef.value)
      chartInstance.value.setOption(chartOptions.value, true)
      chartInstance.value.resize()
    })

    onMounted(() => {
      window.addEventListener('resize', () => {
        chartInstance.value.resize()
      })
    })

    onUnmounted(() => {
      if (chartInstance.value) {
        chartInstance.value.dispose()
      }
    })

    return {
      chartRef,
    }
  },
})
</script>

<template>
  <div
    ref="chartRef"
    class="chart"
  ></div>
</template>
<style lang="scss" scoped>
.chart{
  width: 100%;
  height: 400px;
  padding-top: 16px;
}
</style>
