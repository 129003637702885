export default {
  HEAD: '/statistics/head',
  CONTRACT: '/statistics/contractChannel',
  OVERSEAS: '/statistics/overseaChannel',
  VIDEO_DURATION_OVERSEA: '/statistics/publishVideoDuration',
  VIDEO_DURATION_SIGN: '/statistics/contractVideoDuration',
  VIDEO_OVERSEA: '/statistics/publishVideo',
  VIDEO_SIGN: '/statistics/contractVideo',
  ERROR: '/statistics/errorCount',
}
